// AuthContext.js
import { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(sessionStorage.getItem('authtoken') || null);

  // const login = (newToken) => {
  //   setToken(newToken);
  //   sessionStorage.setItem('authtoken', newToken);
  // };

  const logout = () => {
    setToken(null);
    sessionStorage.removeItem('authtoken');
  };

  return (
    <AuthContext.Provider value={{ token, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
      throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
// AuthContext.js
// AuthContext.js
// import { createContext, useContext, useState, useEffect } from 'react';
// import Apiservice from '../Api/ApiController';

// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//     const [token, setToken] = useState(() => sessionStorage.getItem('authtoken') || null);

//     const login = (newToken) => {
//         setToken(newToken);
//         sessionStorage.setItem('authtoken', newToken);
//     };

//     const logout = () => {
//         setToken(null);
//         sessionStorage.removeItem('authtoken');
//     };

//     useEffect(() => {
//         const checkToken = async () => {
//             if (token) {
//                 try {
//                     await Apiservice.getProfile(); // Example call to validate the token
//                 } catch (error) {
//                     console.error('Token is invalid or expired', error);
//                     logout(); // Logout if the token is invalid
//                 }
//             }
//         };

//         checkToken();
//     }, [token]);

//     return (
//         <AuthContext.Provider value={{ token, login, logout }}>
//             {children}
//         </AuthContext.Provider>
//     );
// };

// export const useAuth = () => {
//     const context = useContext(AuthContext);
//     if (!context) {
//         throw new Error('useAuth must be used within an AuthProvider');
//     }
//     return context;
// };
