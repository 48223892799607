// import Nav from "./component/navbar/NavIgationBar";
// import Footer from "./component/footer/Footers";
import Home from "./component/pages/homepage/HomePage";
import About from "./component/pages/about/About";
import ContactUs from "./component/pages/contactus/ContactUs";
import {  Route, Routes, useLocation } from "react-router-dom";
import ShopAll from "./component/collections/shopall/ShopAll";
import ProductDetails from "./component/collections/product-details/ProductDetails";
import SignUp from "./component/pages/auth/signup/SignUp";
import VerifyPhone from "./component/pages/auth/verifyphone/VerifyPhone";
import ProductList from "./component/collections/shop by category/ProductList";
import PrivateRoute from "./component/pages/auth/PrivateComponent";
import { AuthProvider } from "./Authentication/Authcontext";
import GetProfile from "./component/pages/auth/profile/GetProfile";
import Address from "./component/pages/auth/profile/Address";
import Addtocart from "./component/add-to-cart/Addtocart";
import ChooseUs from "./component/pages/whychooseus/ChooseUs";
import OurProcess from "./component/pages/ourprocess/OurProcess";
import PrivacyPolicy from "./component/pages/PrivacyPolicy/PrivacyPolicy";
import SubscribeProductDetails from "./component/collections/product-details/SubscribeProductDetails";
import { useEffect, useState } from "react";
import Loader from "./Loader";
import WalletPage from "./component/pages/auth/wallet/Wallet";
import AddProfile from "./component/pages/auth/profile/AddProfile";
import SubscriptionList from "./component/subscription/SubscriptionList";
import SearchPage from "./component/pages/searchproduct/SearchProduct";
import Calender from './component/calender/Calender';
import OrderDetails from "./component/pages/orderdetails/Orderdetails";
import TransactionHistroy from "./component/pages/auth/wallet/TransactionHistroy";
import Regionselect from "./component/pages/regionselect/Regionselect";
import Notification from "./component/pages/notification/Notification";
import Updateprofile from './component/pages/auth/profile/Updateprofile'
import LocationRequest from "./component/pages/locationrequest/LocationRequest";
import Locationaddedpage from "./component/pages/locationrequest/Locationaddedpage";
import TermCondition from "./component/pages/termsandcondition/TermCondition";
import ReturnPolicy from "./component/pages/ReturnPolicy/ReturnPolicy";
import Orderhistory from "./component/pages/orderdetails/Orderhistory";
import ReferEarn from "./component/pages/referandearn/ReferEarn";
import VacationMode from "./component/pages/vacation/VacationMode";
import EditAddress from "./component/pages/auth/profile/EditAddress";
export default function Main() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // Simulate loading for 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };
    fetchData();
  }, [location]);

  return (
    <div>
      {loading ? (
        <Loader  /> // Display the Loader component while loading
      ) : (
        <AuthProvider>
          {/* <BrowserRouter> */}
          <Routes>
  <Route path="/" element={<Home />} />
  <Route path="/about" element={<About />} />
  <Route path="/contact-us" element={<ContactUs />} />
  <Route path="/shop-all" element={<ShopAll />} />
  <Route path="/whychooseus" element={<ChooseUs />} />
  <Route path="/ourprocess" element={<OurProcess />} />
  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
  <Route path="/terms-condition" element={<TermCondition/>}/>
  <Route path="/return-policy" element={<ReturnPolicy/>}/>
  <Route path="/product-details/:productId" element={<ProductDetails />} />
  <Route path="/subscribeproduct-details/:productId" element={<SubscribeProductDetails />} />
  <Route path="/categories/products/:categoryId" element={<ProductList />} />
  <Route path="/signup" element={<SignUp />} />
  <Route path="/verify-otp" element={<VerifyPhone />} />
  <Route path="/profile" element={<PrivateRoute children={<GetProfile />} />} />
  <Route path="/addresses" element={<PrivateRoute children={<Address />} />} />
  <Route path="/cart" element={<PrivateRoute children={<Addtocart />} />} />
  <Route path="/addprofile" element={<PrivateRoute children={<AddProfile />} />} />
  <Route path="/wallet" element={<PrivateRoute children={<WalletPage />} />} />
  <Route path="/subscription-list" element={<PrivateRoute children={<SubscriptionList />} />} />
  <Route path="/calender" element={<PrivateRoute children={<Calender />} />} />
  <Route path="/search" element={<SearchPage />} />
  <Route path="/orderdetails/:orderId" element={<PrivateRoute children={<OrderDetails/>}/>}/>
  <Route path="/transactionhistory" element={<PrivateRoute children={<TransactionHistroy/>}/>}/>
  <Route path="/regionselect"  element={<Regionselect/>}/>
  <Route path="/notification" element={<PrivateRoute children={<Notification/>}/>}/>
  <Route path="/updateprofile" element={<PrivateRoute children={<Updateprofile/>}/>}/>
  <Route path="/locationrequest" element={<LocationRequest/>}/>
  <Route path="/locationadded" element={<Locationaddedpage/>}/>
  <Route path="/orderhistory" element={<PrivateRoute children={<Orderhistory/>}/>}/>
  <Route path="/referearn" element={<PrivateRoute children={<ReferEarn/>}/>}/>
  <Route path="/vacation" element={<PrivateRoute children={<VacationMode/>}/>}/>
  <Route path="/editaddress/:addresID" element={<PrivateRoute children={<EditAddress/>}/>}/>
</Routes>

          {/* </BrowserRouter> */}
        </AuthProvider>
       )} 
    </div>
  );
}
