import React, { useEffect, useState } from 'react';
import './footer.css';
import { Link, useNavigate } from 'react-router-dom';
import logo from './desigrologowhite.png';
import Apiservice from '../../Api/ApiController';

export default function Footers() {
const [product,setproduct] = useState([]);
const [config,setconfig] = useState([])
const navigate = useNavigate()
useEffect(()=>{
   Apiservice.Shopall({
   limit: 10,
   offset: 0
   }).then((response)=>{
   if(response.data.status === true){
  
    setproduct(response.data.data.products)
   }
   }).catch((err)=> console.log(err))
},[])
const HandleProductClick = (productId) => {
  navigate(`/product-details/${productId}`);
};

useEffect(()=>{
 Apiservice.Config().then((response)=>{
 if(response.data.status === true){
  setconfig(response.data.data)
 }else{
      
 }
 }).catch((err)=> console.log(err))
},[])
  return (
    <footer style={{ backgroundColor: '#03A99C', color: 'white' }}>
      <div className='footer-above-part'>
        <Link to="/return-policy" className='footer-above-part-text'>Return Policy</Link>
        {/* <hr style={hrStyle}/> */}
        <Link to="/terms-condition" className='footer-above-part-text'>Terms and Conditions</Link>
        {/* <hr style={hrStyle} /> */}
        <Link to='/privacy-policy' className='footer-above-part-text'>Privacy Policy</Link>
      </div>

      <div className='footer-below-part'>
        <div className='footer-container'>
          
          <div className='footer-logo-img'>
            <img src={logo} alt="DesiGro Logo" />
          </div>

          <div className='footer-contact-info'>
            <p>{config.address}</p>
            <p>{config.email}</p>
            <p>{config.phone}</p>
            
          </div>

          <div className='footer-links'>
            <h6 className='text-uppercase fw-bold mb-4'>Shop</h6>
            {product.slice(0,4).map((item,index)=>(
              <div className='footer-links-product' key={item.id} onClick={()=>HandleProductClick(item.id)}>{item.name}</div>
            ))
            }
            <div className='footer-link-shopall'>
             <Link to="/shop-all">view all products</Link>
            </div>
          </div>

          <div className='footer-links'>
            <h6 className='text-uppercase fw-bold mb-4'>About DesiGro</h6>
            <Link to='/about' className='text-reset'>About Us</Link>
            <Link to='/whychooseus' className='text-reset'>Why Choose Us</Link>
            <Link to='/ourprocess' className='text-reset'>Our Process</Link>
            
            {/* <h6 className='text-uppercase fw-bold mb-4'>Resources</h6>
            <Link to='#!' className='text-reset'>FAQs</Link>
            <Link to='#!' className='text-reset'>Media Coverage</Link> */}
          </div>
        </div>
      </div>

      <div className='footer-bottom'>
        <p>© 2024, The Organic Carbon Pvt. Ltd.</p>
        <p><Link className='text-reset fw-bold' to='/'>Desigro</Link></p>
      </div>
    </footer>
  );
}
