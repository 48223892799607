import React, { useEffect, useState } from "react";
import NavIgationBar from "../../../navbar/NavIgationBar";
import Footers from "../../../footer/Footers";
import "./profile.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Apiservice from "../../../../Api/ApiController";


export default function GetProfile() {
  const [profile, setProfile] = useState([]);
  const token = sessionStorage.getItem("authtoken");
  const navigate = useNavigate();
  const [OrderList, setOrderList] = useState([]);

  useEffect(() => {
    const getProfileAPI = async () => {
      try {
        // const response = await axios.get(
        //   "https://desigro.languagenectar.in/api/v1/customer/info",
        //   {
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // );
        const response =  await Apiservice.getProfile()
        if(response.data.status === true){
          console.log(response.data)
          setProfile(response.data);
        }
        
      } catch (error) {
        console.error("Error fetching profile:", error.message);
      }
    };

    if (token) {
      getProfileAPI();
    } else {
    }
  }, [token]);
  const handleLogout = () => {
    if (token) {
      Swal.fire({
        title: "Logout",
        text: "Are you sure you want to logout?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, logout!",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          sessionStorage.removeItem("authtoken");
          navigate("/");
          window.location.reload()
          Swal.fire(
            "Logged Out!",
            "You have been logged out successfully.",
            "success"
          );
        }
      });
    } else {
      Swal.fire({
        title: "Login",
        text: "Hmm, it seems like you're not logged in yet. To access this feature, please log in or create an account. We're excited to have you on board!",
        icon: "warning",
        showCancelButton: true,
      });
    }
  };

  const fetchorderlist = async () => {
    try {
 
       const response =  await Apiservice.orderList({
               limit: 10,
            offset: 0,
       })
      if(response.data.status ===  true){
        setOrderList(response.data.data.orders);
        console.log(response.data)
      }
    } catch (error) {
      console.log("Error in fetching Api", error);
    }
  };
  useEffect(() => {
    fetchorderlist();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 4;
  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  // Filter the orderList to get orders for the current page where is_subscribe is 0
  const ordersOnCurrentPage = OrderList.filter(order => order.is_subscribe === 0).slice(startIndex, endIndex);

  // Calculate the total number of pages based on filtered orders
  const totalPages = Math.ceil(OrderList.filter(order => order.is_subscribe === 0).length / ITEMS_PER_PAGE);

  // Function to handle page changes
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const formatDate = (dateString) => {
    // Use Date object to format the date
    const date = new Date(dateString);
    // Customize the date formatting as needed
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
    return formattedDate;
  };

  const orderdetials = (orderId)=>{
    navigate(`/orderdetails/${orderId}`)
  }
  return (
    <div>
      <NavIgationBar />

      <div className="main-div-profile">
        <div className="wallet-display">
          <div className="wallet-display1">
          <Link to='/wallet' style={{color:'white',fontSize:'20px'}}>
          <span><i class='bx bx-wallet-alt'></i></span>
          </Link>      
           { profile.data ? (
                  <>
                   <span>₹{profile.data.wallet_balance}</span>
                  </>
                ) : (
                  <span>please recharge your wallet</span>
                )}
            
          </div>
        </div>
        <section>
          <div className="sub-div-profile-1">
            <h1>Account</h1>
            <span className="profile-logout-button" onClick={handleLogout}>
              <box-icon name="user"></box-icon>
              <Link style={{ color: "black" }}>Logout</Link>
            </span>
          </div>
        </section>
        <section>
          <div className="sub-div-profile-2">
     

            {/* --------------------------------- */}
            <div>
              <h1>Account Details</h1>
              <div>
                {profile.data ? (
                  <>
                    <p>{profile.data.f_name || "UserName"}</p>
                    <Link to="/updateprofile" style={{ color: "black" }}>Update Profile</Link>
                    
                  </>
                ) : (
                  <Link to="/signup" style={{ color: "black" }}>
                    Please Login
                  </Link>
                )}
              </div>
              <Link to="/addresses" style={{ color: "black" }}>
                View addresses
              </Link>
             <div> <Link to="/subscription-list" style={{ color: "black" }}>
                View Subscription
              </Link></div>
             <div> <Link to="/vacation" style={{ color: "black" }}>
                View Vacation
              </Link></div>
             <div> <Link to="/orderhistory" style={{ color: "black" }}>
                View Order history
              </Link></div>
            </div>
          </div>
        </section>
      </div>
      <Footers />
    </div>
  );
}
