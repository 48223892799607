import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import "./home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import NavIgationBar from "../../navbar/NavIgationBar";
import Footers from "../../footer/Footers";

export default function HomePage() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://desigro.languagenectar.in/api/v1/categories"
      );
      setCategories(response.data.data);
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <NavIgationBar />
  
      <div id="HomePage">
        <div className="home-banner">
          <img
            src={process.env.PUBLIC_URL + "/assets/homepage/home2.png"}
            alt=""
            
          />
        </div>
        <div>
      
        </div>

        <div className="adjustable-container">
          <Swiper
            effect="coverflow"
            loop={true}
            // centeredSlides={true}
            slidesPerView="auto"
            spaceBetween={25}
            className="mySwiper"
            navigation={{
              // Add navigation configuration
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
          >
            {categories &&
              categories.length > 0 &&
              categories.map((category, index) => (
                <SwiperSlide key={index}>
                  <div className="slider-1-image">
                    <NavLink
                      key={category.id}
                      to={`/categories/products/${category.id}`}
                      onClick={() => handleCategoryClick(category.id)}
                    >
                      <img
                        src={`https://desigro.languagenectar.in/storage/app/public/category/${category.image}`}
                        alt=""
                      />
                    </NavLink>
                    <div className="slider-1-text-1-1">
                    <p className="slider-1-text-1">{category.name}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <div></div>
        <div >
            <h1 className="text-center fw-bolder certificates">Certificates</h1>
          <div className="marketing-partner">
            <Swiper
              effect={"coverflow"}
              // grabCursor={true}
              centeredSlides={false}
              slidesPerView={6}
              spaceBetween={20}
              className="mySwiper"
              autoplay={true}
            >
              <SwiperSlide>
                <div className="certificate-slide">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/homepage/certificates.png"
                    }
                    alt="Certificate 1"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="certificate-slide">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/homepage/certificates.png"
                    }
                    alt="Certificate 1"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="certificate-slide">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/homepage/certificates.png"
                    }
                    alt="Certificate 1"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="certificate-slide">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/homepage/certificates.png"
                    }
                    alt="Certificate 1"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="certificate-slide">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/homepage/certificates.png"
                    }
                    alt="Certificate 1"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="certificate-slide">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/homepage/certificates.png"
                    }
                    alt="Certificate 1"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="certificate-slide">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/homepage/certificates.png"
                    }
                    alt="Certificate 1"
                  />
                </div>
              </SwiperSlide>
         
             
          
             
             
       
            </Swiper>
          </div>
        </div>
        <div></div>
        <div className="homepage-2-banner">
         <img src={process.env.PUBLIC_URL + "/assets/homepage/home4.png"} alt="" />
        </div>

        <div>
          {/* <Swiper
            effect={"coverflow"}
            grabCursor={true}
            loop={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            spaceBetween={"30"}
            className="mySwiper"
          >
            <SwiperSlide>
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/homepage/homepage.png"}
                  alt=""
                  width={"150px"}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/homepage/homepage.png"}
                  alt=""
                  width={"150px"}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/homepage/homepage.png"}
                  alt=""
                  width={"150px"}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/homepage/homepage.png"}
                  alt=""
                  width={"150px"}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/homepage/homepage.png"}
                  alt=""
                  width={"150px"}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/assets/homepage/homepage.png"}
                  alt=""
                  width={"150px"}
                />
              </div>
            </SwiperSlide>
          </Swiper> */}
        </div>
        <div className="mock-up-home">
          <div className="mock-up-text">
            <h3>
              We’ve got everything to bring you convenience, trust and assurance
            </h3>
            <p>
              Desi Gro App is the gateway to all things organic and
              chemical-free, with traceable quality. Here are 3 simple steps to
              use the app and manage your subscriptions with DesiGro A2. The app
              is available on Android.
            </p>
            <div>
            <img src={process.env.PUBLIC_URL + "assets/img/googleplaynew.png"} alt="" />
            </div>
          </div>
          <div className="mock-up-img">
            <img
              src={process.env.PUBLIC_URL + "assets/img/542163805.png"}
              alt=""
            />
            <img
              src={process.env.PUBLIC_URL + "assets/img/542163807.png"}
              alt=""
            />
            <img
              src={process.env.PUBLIC_URL + "assets/img/542163808.png"}
              alt=""
            />
          </div>
        </div>
        <div className="bottom-home5-img">
          <img src={process.env.PUBLIC_URL + "assets/homepage/home5.png"} alt="" />
        </div>
      </div>
      <Footers />
    </div>
  );
}
