import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './WalletPage.css'; // Import your CSS file containing styles
import Swal from 'sweetalert2';
import Apiservice from '../../../../Api/ApiController';
import Navbar from '../../../navbar/NavIgationBar';
import Footers from '../../../footer/Footers';
import { useNavigate } from 'react-router-dom';

function WalletPage() {
  const [balance, setBalance] = useState(0);
  const [amountToAdd, setAmountToAdd] = useState("");
  const [profile,setprofile]= useState([]);
  const token = sessionStorage.getItem('authtoken');
  const navigate =  useNavigate()
  const handleAmountChange = (event) => {
    const value = event.target.value;
    // Only update the amount if the value is a non-negative number
    if (!isNaN(value) && parseInt(value) >= 0) {
      setAmountToAdd(value);
    }
  };

  const handlePredefinedAmountClick = (amount) => {
    setAmountToAdd(amount.toString());
  };

  

  const handleAddMoney = async () => {
    try {
     

      const options = {
        key: 'rzp_test_WZu8B3H1Bec0W9', 
        amount: parseInt(amountToAdd) * 100, 
        currency: 'INR',
        name: 'My Wallet',
        description: 'Add Money to Wallet',

        handler: async function  (response) {
          setBalance(balance + parseInt(amountToAdd));
          
          const transactionId = response.razorpay_payment_id;
          await WalletRecharge(transactionId)
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  

  const WalletRecharge = async  (transactionId)=>{
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    console.log(formattedDate)
    try{
  

      const response =  await Apiservice.WalletRecharge({
        payment_method: "razor_pay",
        mode : "credit",
        order_amount:amountToAdd,
        transaction_id:transactionId,
        subscription_date:formattedDate
      })
      if(response.data.status === true){
        console.log(response.data.data)
        Swal.fire({
            title:'Wallet reacharged Succesfrully',
            icon:'success',
            showCancelButton:true
        })
        getProfileAPI()
      }else{
        console.log("Not successful")
      }
    }catch (error) {

    }
  }
  const getProfileAPI = async () => {
    try {
  
      const response = await Apiservice.getProfile()

      if(response.data.status === true){

        setprofile(response.data.data);
      }else{
        console.error(response);
      }
      
      
    } catch (error) {
      console.error("Error fetching profile:", error.message);
    }
  };
    useEffect(()=>{
      getProfileAPI()
    },[])
 const transactionhistory = ()=>{
  navigate('/transactionhistory')
 }
  return (
    <div>
    <Navbar/>
    <div className="wallet-container">
      <div className="header">
        <h1 className="wallet-heading">My Wallet</h1>
        <div className="balance">Balance: ₹{profile.wallet_balance}</div>
      </div>
      <div className="actions">
      <input
  type="text"
  className="amount-input"
  placeholder="Enter amount"
  value={amountToAdd}
  onChange={handleAmountChange}
  min="0"
/>

        <div className="predefined-amounts">
          <button className="predefined-btn" onClick={() => handlePredefinedAmountClick(1000)}>₹1000</button>
          <button className="predefined-btn" onClick={() => handlePredefinedAmountClick(2000)}>₹2000</button>
          
        </div>
        <button className="add-money-btn" onClick={handleAddMoney}>Add Money</button>
      </div>
      <section>
        <div className='transaction-history-btn' onClick={transactionhistory}>
          <h6>Transaction History</h6>
          <i class='bx bx-history'></i>
        </div>
      </section>
    </div>
    <Footers/>
    </div>
  );
}

export default WalletPage;
