import React, { useEffect, useState } from "react";
import "./CartPopup.css"; // Import your CSS file for styling
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../navbar/DesiGrologo.png";
import useRazorpay from "react-razorpay";
import Swal from "sweetalert2";

import { QuantitySelector } from "../helper/QuantitySelector";
import Apiservice from "../../Api/ApiController";
import Footers from "../footer/Footers";
import Navbar from "../navbar/NavIgationBar";
import { Api_Base_Url } from "../../Api/ApiController";
export default function AddToCart() {
  const navigate = useNavigate();
  const [CartItems, setCartItems] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalitem, settotalitems] = useState();
  const token = localStorage.getItem("authtoken");
  const [Razorpay] = useRazorpay();
  const [addresslist, setaddresslist] = useState([]);
  const [selectedaddress, setSelectedAddressId] = useState();
  // const [upquantity,setupquantity] =  useState()
  const [deliverondate, setdeliverondate] = useState();
  const [blur, setblur] = useState();
  const [couponcode, setcouponcode] = useState([]);
  const [code, setcode] = useState();
  const [coupondetails, setcoupondetails] = useState();
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [pricewithoutdiscount, setpricewithoutdiscount] = useState();
  const [discountedamount, setdiscountedamount] = useState();
  const [profile, setprofile] = useState([]);
  const [config, setconfig] = useState([]);
  const [paymentmethod,setpaymentmethod] =  useState("online_payment")
  console.log(paymentmethod);
  const toggleBottomSheet = () => {
    setShowBottomSheet(!showBottomSheet);
  };
  const handleOverlayClick = () => {
    setShowBottomSheet(false);
  };
  const CartDetails = async () => {
    try {
      const response = await Apiservice.cartdetails();
      if (response.data.status === true) {
        setCartItems(response.data.data);
        updateGrandTotal(response.data.data);
        updatetotalitem(response.data.data);
        console.log(response.data.data);
      } else {
        console.log("Error in Fetching the Data");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Apiservice.getProfile()
      .then((response) => {
        if (response.data.status === true) {
          console.log(response.data.data);
          setprofile(response.data.data);
        } else {
        }
      })
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    Apiservice.Config()
      .then((response) => {
        if (response.data.status === true) {
          console.log(response.data);
          setconfig(response.data.data);
        } else {
          console.log(response);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const updateGrandTotal = (items) => {
    const total = items.reduce((acc, item) => {
      if (item.discount_type === "percent") {
        return (
          acc +
          (item.price - item.price * (item.discount / 100)) * item.quantity
        );
      } else {
        return acc + (item.price - item.discount) * item.quantity;
      }
    }, 0);
    setGrandTotal(total);
    const withoutdiscount = items.reduce((acc, item) => {
      return acc + item.price * item.quantity;
    }, 0);
    setpricewithoutdiscount(withoutdiscount);
  };

  const updatetotalitem = (items) => {
    const totalitem = new Set(items.map((item) => item.id)).size;
    settotalitems(totalitem);
  };
  // -------------------------------------------------------------------

  const handlePayment = async () => {
    
    if (!selectedaddress) {
        Swal.fire({
        icon: "info",
        text: "Please select an address before proceeding to checkout.",
      });
      return;
    }
    if (!selectedOption) {
      Swal.fire({
        icon: "info",
        text: "Please Select Delivery Instruction",
      });
      return;
    }
    let totalAmount = 0;
    if (coupondetails) {
      totalAmount = (
        parseFloat(discountedamount) -
        (pricewithoutdiscount - grandTotal) +
        parseFloat(config.delivery_charge)
      ).toFixed(2);
    } else {
      totalAmount = (
        parseFloat(grandTotal) + parseFloat(config.delivery_charge)
      ).toFixed(2);
    }
    
    if(paymentmethod === 'online_payment'){
    const options = {
      key: "rzp_test_WZu8B3H1Bec0W9",
      amount: totalAmount * 100,
      currency: "INR",
      name: "Desi Gro",
      description: "Test Transaction",
      image: { logo },

      handler: async function (response) {
        // Call the placeOrder function with the generated payment ID
        await placeOrder(response.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      console.log(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    
    rzp1.open();
  }else{
   await placeOrder()
  }
  };
  //====================================================================
  console.log({ coupon_code: code });
  const placeOrder = async (paymentId) => {
    let totalAmount = 0;
    if (coupondetails) {
      totalAmount = (
        parseFloat(discountedamount) -
        (pricewithoutdiscount - grandTotal) +
        parseFloat(config.delivery_charge)
      ).toFixed(2);
    } else {
      totalAmount = (
        parseFloat(grandTotal) + parseFloat(config.delivery_charge)
      ).toFixed(2);
    }
    try {
      const response = await Apiservice.Placeorder({
        cart: CartItems,
        order_amount: totalAmount,
        payment_method: paymentmethod,
        payment_type:paymentmethod,
        is_subscribe: 0,
        address: selectedaddress,
        region_id: selectedaddress.region_id,
        city_id: selectedaddress.city_id,
        due_amount: totalAmount,
        delivery_instructions: selectedOption,

      });
      if (response.data.status === true) {
        // console.log(response.data.data);
        Swal.fire({
          title: "Order Placed Successfully",
          icon: "success",
          showCancelButton: true,
        });
        setCartItems([]);
      } else {
        console.error("Error placing the order:", response.data.message);
        // Handle the error as needed
      }
    } catch (error) {
      console.error("Error placing the order:", error);
      // Handle the error as needed
    }
  };
  //========================================================================
  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        
        const response = await Apiservice.FetchAddresslist();
        if (response.data.status === true) {
          setaddresslist(response.data.data);
        } else {
          console.error("Error fetching addresses:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses();
  }, [token]);

  //======================================================================

  const RemoveCartItem = async () => {
    try {
      const response = await Apiservice.RemovecartItem({
        key: CartItems.map((item) => item.id),
      });
      if (response.data.status === true) {
        CartDetails();
      }
    } catch (error) {
      console.log(error);
    }
  };
  //-------------------------------------------------------------------
  const Increase = (itemId) => {
    const updatedQuantity =
      CartItems.find((item) => item.id === itemId).quantity + 1;
    updatecart(itemId, updatedQuantity);
  };

  const Decrease = (itemId) => {
    const updatedQuantity = Math.max(
      CartItems.find((item) => item.id === itemId).quantity - 1,
      1
    ); // Ensure quantity doesn't go below 1
    updatecart(itemId, updatedQuantity);
  };

  // -------------------------------------------------------------------

  const updatecart = async (itemId, upQuantity) => {
    try {
      const response = await axios.put(
        `${Api_Base_Url}customer/order/update`,
        {
          key: itemId,
          quantity: upQuantity,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!itemId) {
        console.error("Cart key or ID is required!");
        return; 
      }
      if (!upQuantity) {
        console.error("The quantity field is required.");
        return; 
      }

      if (response.data.status === true) {
        CartDetails(); 
      } else {
        console.error("Error updating the cart:", response.data);
      }
    } catch (error) {
      console.error("Error updating the cart:", error);
    }
  };

  //======================================================================
  useEffect(() => {
    CartDetails();
  }, []);
  const ContinueShopping = () => {
    navigate("/shop-all");
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const today = new Date();
    const formattedDate = formatDate(today);
    setdeliverondate(formattedDate);
    setblur(formattedDate);
  }, []);

  useEffect(() => {
    Apiservice.CouponList({})
      .then((response) => {
        if (response.data.status === true) {
          console.log(response.data);
          setcouponcode(response.data.data);
        } else {
          console.log(response);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handlecouponcodecopy = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      Swal.fire({
        icon: "success",
        text: "Coupon code copied to clipboard: " + code,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
      });
    });
  };
  const handleRadioChange = (option) => {
    setSelectedOption(option === selectedOption ? null : option);
  };
  // const totalAmount = () => {
  //   const subtotal = grandTotal.toFixed(2);
  //   const deliveryCharge = config.delivery_charge;
  //   return (parseFloat(subtotal) + parseFloat(deliveryCharge)).toFixed(2);
  // };

  const ApplyCoupon = (e) => {
    e.preventDefault();
    Apiservice.CouponApply({
      code: code,
      order_amount: pricewithoutdiscount,
    })
      .then((response) => {
        if (response.data.status === true) {
          setcoupondetails(response.data.data);
          console.log(response.data.data);
          Swal.fire({
            icon: "success",
            text: response.data.message,
          });
          // coupondiscount()
        } else {
          console.log(response);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const date = new Date();
    const formattedDate = formatDate(date);

    // Check if coupondetails is defined and not null
    if (coupondetails && coupondetails.expire_date) {
      if (formattedDate < coupondetails.expire_date) {
        let discountedprice = 0;
        if (coupondetails.discount_type === "percent") {
          discountedprice =
            pricewithoutdiscount -
            (pricewithoutdiscount -
              pricewithoutdiscount * (coupondetails.discount / 100));
          setdiscountedamount(discountedprice);
        } else {
          discountedprice = pricewithoutdiscount - coupondetails.discount;
          setdiscountedamount(discountedprice);
        }
      } else {
        Swal.fire({
          icon: "error",
          text: "Coupon Is Not Valid",
        });
      }
    } else {
      console.log("Error: coupondetails object is undefined or null");
      // Handle the case where coupondetails is undefined or null
    }
  }, [coupondetails, pricewithoutdiscount]);
  const addmoreitem = () => {
    navigate("/shop-all");
  };

  const editaddress = (addressid) => {
    navigate(`/editaddress/${addressid}`);
  };
  return (
    <div>
      <Navbar />
      <div>
        {CartItems.length > 0 ? (
          <div className="cart-container">
            <section className="cart-section">
              <div className="cart-header">
                <h1>Your Cart</h1>
              </div>

              <div className="cart-message">
                <p>
                  Delicious choices! Enjoy free shipping on your dairy products!
                </p>
              </div>

              <div className="cart-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {CartItems.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <div className="product-image-cart">
                            <img
                              src={`https://desigro.languagenectar.in/storage/app/public/product/${item.thumbnail}`}
                              width={"100px"}
                              alt="Dairy Product"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="product-info">
                            <h6>{item.product.name}</h6>
                            <p>{item.product.size}</p>
                          </div>
                        </td>
                        <td>
                          <div className="quantity">
                            <QuantitySelector
                              itemId={item.id}
                              quantity={item.quantity}
                              onDecrease={() => Decrease(item.id)}
                              onIncrease={() => Increase(item.id)}
                            />
                          </div>
                        </td>
                        <td>
                          <strike style={{ color: "red" }}>
                            ₹{item.price * item.quantity}
                          </strike>
                          <div className="total">
                            {item.discount_type === "percent" ? (
                              <span>
                                ₹
                                {(item.price -
                                  item.price * (item.discount / 100)) *
                                  item.quantity}
                              </span>
                            ) : (
                              <span>
                                ₹{(item.price - item.discount) * item.quantity}
                              </span>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="cart-action-delete">
                            <button
                              onClick={() => RemoveCartItem(item.id)}
                              className="cart-delete-btn"
                            >
                              <i className="bx bx-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <section onClick={addmoreitem} className="add-more-item-cart">
                Add more Item+
              </section>
              <div className="address-selection">
                {addresslist.length > 0 ? (
                  <>
                    <h2>Select Address</h2>
                    <div className="address-cards">
                      {addresslist.map((address, index) => (
                        <div
                          className={`address-card ${
                            selectedaddress && selectedaddress.id === address.id
                              ? "selected"
                              : ""
                          }`}
                          key={index}
                          onClick={() => setSelectedAddressId(address)}
                        >
                          <div className="edit-address-section">
                            <h3>{address.address_type}</h3>
                            <span onClick={() => editaddress(address.id)}>
                              <i class="bx bx-edit-alt"></i>
                            </span>
                          </div>
                          <p>
                            {address.address}, {address.location},{" "}
                            {address.city_id}, {address.region_id}
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <NavLink to="/addresses">Add Address</NavLink>
                  </>
                )}
              </div>
              <section className="">
                <div className="deliver-on-date">
                  <label htmlFor="">Deliver On : </label>
                  <span>
                    <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <input
                    type="date"
                    min={blur}
                    value={deliverondate}
                    onChange={(e) => setdeliverondate(e.target.value)}
                  />
                </div>
              </section>

              <section>
                <section className="section-container-bottom">
                  <div
                    className="best-available-offer"
                    onClick={toggleBottomSheet}
                  >
                    View best available offer
                    <i
                      class="bx bx-chevron-right"
                      style={{ color: "green" }}
                    ></i>
                  </div>
                </section>
                {showBottomSheet && (
                  <>
                    <div className="overlay" onClick={handleOverlayClick}></div>
                    <div className="bottom-sheet">
                      <div>
                        {couponcode.length > 0 ? (
                          <div>
                            {couponcode.map((couponcode, index) => (
                              <div className={`coupon-item`} key={index}>
                                <div
                                  className="coupon-details"
                                  // onClick={() =>
                                  //   handleCouponSelection(index, couponcode.code , couponcode.id)
                                  // }
                                >
                                  <h6>{couponcode.code}</h6>
                                  <p>
                                    Apply this coupon to get
                                    <span>
                                      {couponcode.discount_type === "amount" ? (
                                        <span>
                                          {" "}
                                          Rs.{couponcode.discount} OFF
                                        </span>
                                      ) : (
                                        <span>{couponcode.discount}% OFF</span>
                                      )}
                                    </span>
                                  </p>
                                </div>
                                <div
                                  className="copy-icon-coupon"
                                  onClick={() =>
                                    handlecouponcodecopy(couponcode.code)
                                  }
                                >
                                  <i class="bx bx-copy"></i>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div>You do not have any Coupon</div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </section>

              <section>
                <div>
                  <form action="">
                    <div className="coupon-code-section">
                      <input
                        type="text"
                        className="coupon-code-input"
                        onChange={(e) => setcode(e.target.value)}
                        placeholder="Enter Coupon Code"
                      />
                      <button onClick={(e) => ApplyCoupon(e)} type="submit">
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
              </section>

              <section className="delivery-instruction-section">
                <div>
                  <h6>Delivery Instruction</h6>
                </div>
                <div className="delivery-instruction-1">
                  <label htmlFor="ringDoorBell" className={`delivery-instruction-sub-1 ${  selectedOption === "Ring Door bell" ? 'color-green' : ''}`}>
                    <i class="bx bx-bell"></i>
                    Ring Door bell
                  </label>
                  <div className="delivery-instruction-sub-2">
                    <input
                    id="ringDoorBell"
                      type="radio"
                      name="delivery_instruction"
                      checked={selectedOption === "Ring Door bell"}
                      onChange={() => handleRadioChange("Ring Door bell")}
                    />
                  </div>
                </div>
                <hr />
                <div className="delivery-instruction-1">
                  <label  htmlFor="DropatTheDoor"className={`delivery-instruction-sub-1 ${  selectedOption === "Drop at the Door" ? 'color-green' : ''}`}>
                    <i class="bx bx-door-open"></i>
                    Drop at the Door
                  </label>
                  <div className="delivery-instruction-sub-2">
                    <input
                     id="DropatTheDoor"
                      type="radio"
                      name="delivery_instruction"
                      checked={selectedOption === "Drop at the Door"}
                      onChange={() => handleRadioChange("Drop at the Door")}
                    />
                  </div>
                </div>
                <hr />
                <div className="delivery-instruction-1">
                  <label htmlFor="InHandDelivery" className={`delivery-instruction-sub-1 ${  selectedOption === "In Hand Delivery" ? 'color-green' : ''}`}>
                    <i class="bx bxs-hand"></i>
                    In Hand Delivery
                  </label>
                  <div className="delivery-instruction-sub-2">
                    <input  
                      id="InHandDelivery"
                      type="radio"
                      name="delivery_instruction"
                      checked={selectedOption === "In Hand Delivery"}
                      onChange={() => handleRadioChange("In Hand Delivery")}
                    />
                  </div>
                </div>
                <hr />
                <div className="delivery-instruction-1">
                  <label  htmlFor="KeepInBag" className={`delivery-instruction-sub-1 ${  selectedOption === "Keep In Bag" ? 'color-green' : ''}`}>
                    <i class="bx bxs-shopping-bag"></i>
                    Keep In Bag
                  </label>
                  <div className="delivery-instruction-sub-2">
                    <input
                    id="KeepInBag"
                      type="radio"
                      name="delivery_instruction"
                      checked={selectedOption === "Keep In Bag"}
                      onChange={() => handleRadioChange("Keep In Bag")}
                    />
                  </div>
                </div>
                <hr />
                <div className="delivery-instruction-1">
                  <label htmlFor="NoPreferences" className={`delivery-instruction-sub-1 ${  selectedOption === "No Preferences" ? 'color-green' : ''}`}>
                    <i class="bx bxs-cog"></i>
                    No Preferences
                  </label>
                  <div className="delivery-instruction-sub-2">
                    <input
                    id="NoPreferences"
                      type="radio"
                      name="delivery_instruction"
                      checked={selectedOption === "No Preferences"}
                      onChange={() => handleRadioChange("No Preferences")}
                    />
                  </div>
                </div>
                <hr />
              </section>
              <section className="additional-instruction-cart">
                <h6>Additional Instruction</h6>
                <input type="text" placeholder="optional" />
              </section>

              <section className="payment-details-section-cart">
                <h5>Payment Details</h5>
                <hr />
                <div className="item-total-cart">
                  <div>Item Total</div>
                  <div>₹{pricewithoutdiscount}</div>
                </div>
                <div className="product-discount-cart">
                  <div>Product Discount</div>
                  <div>₹{pricewithoutdiscount - grandTotal}</div>
                </div>
                <div className="product-discount-cart">
                  <div>Coupon Discount</div>
                  {coupondetails ? (
                    <div>₹{pricewithoutdiscount - discountedamount}</div>
                  ) : (
                    "₹0.0"
                  )}
                </div>
                <div className="product-discount-cart">
                  <div>Delivery Charge</div>
                  <div className="product-deliverycharge-cart">
                    <p style={{ color: "red" }}>
                      <strike>₹{config.max_delivery_charge}</strike>
                    </p>
                    <p>₹{config.delivery_charge}</p>
                  </div>
                </div>
                <div className="product-discount-cart">
                  <div>Saved</div>
                  <div>
                    ₹{config.max_delivery_charge - config.delivery_charge}
                  </div>
                </div>
                <hr />
                <div className="product-discount-cart">
                  <div className="total-items">
                    <p>Grand Total</p>
                  </div>
                  <div className="order-total">
                    {/* <h3>₹{(grandTotal.toFixed(2)) + config.delivery_charge}</h3> */}
                    {coupondetails ? (
                      <h3>
                        {(
                          parseFloat(discountedamount) -
                          (pricewithoutdiscount - grandTotal) +
                          parseFloat(config.delivery_charge)
                        ).toFixed(2)}
                      </h3>
                    ) : (
                      <h3>
                        {(
                          parseFloat(grandTotal) +
                          parseFloat(config.delivery_charge)
                        ).toFixed(2)}
                      </h3>
                    )}
                  </div>
                </div>
              </section>

              <div className="cart-actions">
                {/* <button onClick={ContinueShopping} className="btn-continue-shopping">Continue Shopping</button> */}
                {profile.is_booking === "enabled" ? (
                  <select value={paymentmethod} onChange={(e)=>setpaymentmethod(e.target.value)} className="add-to-cart-payment-method" name="" id="">
                    <option value="online_payment">Pay Online</option>
                    <option value="wallet">
                      <i
                        class="bx bxs-wallet-alt bx-flip-vertical"
                        style={{ color: "#22550d" }}
                      ></i>
                      Wallet
                    </option>
                  </select>
                ) : (
                  <div className="pay-online-add-to-cart">
                  <p>Pay online</p>
                  </div>
                )}

                {CartItems[0] ? (
                  <button
                    className="btn-proceed-to-checkout"
                    onClick={handlePayment}
                  >
                    Proceed to Checkout
                  </button>
                ) : (
                  ""
                )}
              </div>
            </section>
          </div>
        ) : (
          <div className="empty-cart1">
            <div className="empty-cart">
              <h2>Your Cart is Empty</h2>
              <p>
                Looks like you haven't added any items to your cart yet. Let's
                fix that!
              </p>
              <button
                onClick={ContinueShopping}
                className="btn-continue-shopping"
              >
                Start Shopping
              </button>
            </div>
          </div>
        )}
      </div>
      <Footers />
    </div>
  );
}
